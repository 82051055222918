import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api";
import { showLoading } from "../slice/loadingSlice";

const createAccountAsyncThunk = (method, type, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);
    const apiRequest = {
      method: method,
      headers: { "Content-Type": "application/json" },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);

        // const token = response.data.login_info[0]['token'];
        // console.log('tokenaction',token)
        // localStorage.setItem("token", token);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      // dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  });

export const candidateLogin = createAccountAsyncThunk(
  "post",
  "candidateLogin",
  "CompanyCandidateRegister/candidate_login/"
);
export const candidateSetPassword = createAccountAsyncThunk(
  "post",
  "candidateSetPassword",
  "CompanyCandidateRegister/add_candidate/"
);
export const forgotPassword = createAccountAsyncThunk(
  "post",
  "forgotPassword",
  "ClientUser/forgot_password/"
);

export const setNewPassword = createAccountAsyncThunk(
  "post",
  "setNewPassword",
  "ClientUser/set_new_password/"
);

export const candidateDetailsSubmission = createAccountAsyncThunk(
  "post",
  "candidateDetailsSubmission",
  "CompanyCandidateRegister/get_reference_data/"
);

export const candidateConcentVer = createAccountAsyncThunk(
  "post",
  "candidateConcentVer",
  "CompanyCandidateRegister/get_reference_candidate_data/"
);

export const updateCandidateAcceptStatus = createAccountAsyncThunk(
  "post",
  "updateCandidateAcceptStatus",
  "CompanyCandidateRegister/update_candidate_accept_status/"
);
