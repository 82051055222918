import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.css";

function App() {
  // Move the lazy loading inside the functional component
  const AppRouter = lazy(() => import("../src/routes/Routeindex"));

  return (
    <Provider store={store}>
      <Router>
        {/* Suspense should wrap the lazy loaded component */}
        <Suspense fallback={<div>loading...</div>}>
          {/* Use the lazy loaded component directly */}
          <AppRouter />
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
